
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'checkout-address-detail',
  components: {
    CheckoutLogin: require('./../address/CheckoutLogin.vue').default,
    CheckoutAddressBilling: require('./../address/BillingAddress.vue').default,
    CheckoutAddressShipping: require('./../address/ShippingAddress.vue').default,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: false }) private isLoggedIn: boolean;
  @Prop({ required: false, default: [] }) private addresses: Array<object>;

  private checkboxChecked: boolean = false;

  private get newsletter(): boolean {
    return this.$store.state['CheckoutGlobal']['newsletterSubscribe'];
  }

  private set newsletter(value) {
    this.$store.dispatch('CheckoutGlobal/setNewsletterSubscribe', value);
  }

  private get billingIsSameAsShipping(): boolean {
    this.checkboxChecked = !this.$store.getters['CheckoutAddress/billingIsSameAsShipping'];
    return this.$store.getters['CheckoutAddress/billingIsSameAsShipping'];
  }

  private toggleBillingIsSameAsShipping(): void {
    this.$store.dispatch('CheckoutAddress/toggleBillingIsSameAsShipping');
  }
}
