export const transformMeasureUnit = (value: string): string => {
    let result = '';

    switch (value) {
        case 'STUK':
            result = 'stuk';
            break;
        case 'M1':
            result = 'm<sup>1</sup>';
            break;
        case 'M2':
            result = 'm<sup>2</sup>';
            break;
        default:
            result = value;
            break;
    }
    return ` per ${result}`;
}

export const showMeasureUnit = (value: string): boolean => {
    return null !== value && value !== 'STUK';
}
