import Vue from 'vue';

const initialState = () => {
    return {
        useListSwatch: true,
        itemsAddedToCart: [],
        selectedProducts: {},
    }
};

const state = initialState();

const mutations = {
    SET_PRODUCT(state: object, product: object): void {
        const productObject = Object.values(product)[0];
        if (productObject['set_qty'] === 0) {
            Vue.delete(state['selectedProducts'], Object.keys(product)[0]);
            return;
        }

        Vue.set(state['selectedProducts'], Object.keys(product)[0], productObject);
    },
    RESET_PRODUCT(state: object): void {
        Vue.set(state, 'selectedProducts', {});
    },
    SET_ITEMS_TO_CART(state: object, itemSku: string): void {
        state['itemsAddedToCart'].push(itemSku);
    },
    RESET_ITEMS_TO_CART(state: object): void {
        Vue.set(state, 'itemsAddedToCart', []);
    }
};

const actions = {
    setProduct({commit}, payload: object): void {
        commit('SET_PRODUCT', payload);
    },
    async resetProductStates({commit}): Promise<void> {
        commit('RESET_PRODUCT', {});
    },
    addSkuToAddedItems({commit}, payload: string): void {
        commit('SET_ITEMS_TO_CART', payload);
    },
    async resetAddedItems({commit}): Promise<void> {
        commit('RESET_ITEMS_TO_CART');
    }
};

const getters = {
    getSelectedProducts: (state: object) => state['selectedProducts'],
    getUseListSwatch: (state: object) => state['useListSwatch'],
    canAddToCart: (state: object) => Object.keys(state['selectedProducts']).length > 0,
    getProductTotal: (state: object) => {
        return {
            'incl_tax': Object.values(state['selectedProducts']).reduce((acc, product) => acc + product['set_row_total_incl_tax'], 0),
            'excl_tax': Object.values(state['selectedProducts']).reduce((acc, product) => acc + product['set_row_total_excl_tax'], 0),
        }
    },
    getProductPayload: (state: object) => Object.values(state['selectedProducts']).map(product => {
        return {
            sku: product['set_sku'],
            qty: product['set_qty'],
            product_option: {
                extension_attributes: {
                    configurable_item_options: product['set_selected_options'].map((option: object) => {
                        return {
                            option_id: option['attributeId'],
                            option_value: option['selectedValue']
                        };
                    })
                }
            }
        };
    }),
    getItemsAddedToCart: (state: object) => state['itemsAddedToCart'],
};

const ProductView = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default ProductView;
