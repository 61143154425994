const initialState = () => {
    return {
        messages: [],
    };
};

const state = initialState();

const mutations = {
    SET_MESSAGE(state: object, payload: object): void {
        state['messages'].push(payload);
    },
    REMOVE_MESSAGE(state: object, payload: object): void {
        state['messages'].splice(state['messages'].indexOf(payload), 1);
    },
};

const actions = {
    addGlobalMessage({commit}, payload: object): void {
        commit('SET_MESSAGE', payload);
        setTimeout(() => {
          commit('REMOVE_MESSAGE', payload);
        }, 5000);
    },
};

const GlobalMessages = {
    namespaced: true,
    state,
    mutations,
    actions,
};

export default GlobalMessages;
