
import {extend, ValidationProvider} from 'vee-validate';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {integer, max, min, regex, required} from 'vee-validate/dist/rules';
import {appLocalStorage} from '../../../../shared/services';

extend('required', required);
extend('integer', integer);
extend('min', min);
extend('max', max);
extend('regex', regex);

@Component({
  name: 'checkout-address-autocomplete',
  components: {
    ValidationProvider,
  },
})
export default class extends Vue {
  @Prop() readonly type: string;

  protected autoComplete: object = {
    postcode: null,
    houseNr: null,
    houseNrAdd: null,
  };

  private isLoading: boolean = false;
  private hasResolveError: boolean = false;

  private resolvedAddress: object = {};
  private resolveManually: boolean = false;
  private availableHouseNrAdditions: string[] = [];

  private async postcodeLookup(): Promise<void> {
    if (this.resolveManually || null === this.autoComplete['postcode'] || null === this.autoComplete['houseNr']) {
      return;
    }

    this.isLoading = true;

    try {
      const {data} = await this.$solarClient.get(`/api/postcode/lookup/${this.autoComplete['postcode']}/${this.autoComplete['houseNr']}`);

      this.resolvedAddress = data;
      this.availableHouseNrAdditions = this.resolvedAddress['houseNumberAdditions'] || [];

      //Reset the house number addition if it is not in the list of available house number additions
      if (this.availableHouseNrAdditions.length && ! this.availableHouseNrAdditions.includes(this.autoComplete['houseNrAdd'])) {
        this.autoComplete['houseNrAdd'] = this.availableHouseNrAdditions[0] !== '' ?
            this.availableHouseNrAdditions[0] :
            null;
      }

      this.$parent['inputAddress']['street'] = [this.resolvedAddress['street'], this.resolvedAddress['house_nr'], this.autoComplete['houseNrAdd'] || this.resolvedAddress['houseNumberAddition']];
      this.$parent['inputAddress']['postcode'] = this.resolvedAddress['zipcode'];
      this.$parent['inputAddress']['city'] = this.resolvedAddress['place'];

      this.hasResolveError = false;
    } catch (e) {
      if (e.response.status === 400) {
        this.hasResolveError = true;
        this.$parent['inputAddress']['street'] = ['', this.autoComplete['houseNr'], this.autoComplete['houseNrAdd'] || ''];
        this.$parent['inputAddress']['postcode'] = this.autoComplete['postcode'];
        this.$parent['inputAddress']['city'] = '';
      } else {
        console.error(e);
      }
    }

    this.isLoading = false;
  }

  private async changeHouseNrAddition(): Promise<void> {
    this.$parent['inputAddress']['street'][2] = this.autoComplete['houseNrAdd'];
  }

  private resolveAddress(): void {
    this.postcodeLookup();
    this.resolveManually = false;
  }

  private created(): void {
    if (appLocalStorage.hasItem(`checkout-autocomplete-${this.type}-address`)) {
      this.autoComplete = appLocalStorage.getItem(`checkout-autocomplete-${this.type}-address`);
    }

    if (null !== this.autoComplete['postcode'] && null !== this.autoComplete['houseNr']) {
      this.postcodeLookup();
    }
  }

  @Watch('autoComplete', {deep: true})
  handle(nv, ov) {
    appLocalStorage.setItem(`checkout-autocomplete-${this.type}-address`, nv);
  }
}
