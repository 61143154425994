window.addEventListener('load', () => {
  const offCanvasClick = document.querySelectorAll('[data-toggle]');

  for (let i = 0; i < offCanvasClick.length; i++) {
    if (offCanvasClick[i].getAttribute('data-toggle') === 'offCanvas') {
      offCanvasClick[i].addEventListener('click', (e) => {
        toggleOffCanvas(e);
      });
    }
  }
});

document.addEventListener(
  'keydown',
  (e) => {
    if (e.which === 27 || e.keyCode === 27) {
      for (let i = 0; i < document.getElementsByClassName('off-canvas').length; i++) {
        if (document.getElementsByClassName('off-canvas')[i].classList.contains('is-open')) {
          animateOffCanvas('open');
        }
      }
    }
  },
  false
);

function toggleOffCanvas(event) {
  const menuEl = document.getElementById('offCanvas');
  if (menuEl.classList.contains('is-open')) {
    event.stopPropagation();
    document.getElementsByTagName('body')[0].classList.remove('no-scroll');
    menuEl.classList.remove('is-open');

    animateOffCanvas('open');

    setTimeout(() => {
      menuEl.classList.add('is-closed');
    }, 500);
  } else {
    animateOffCanvas('close');
  }
}

function animateOffCanvas(type) {
  const bars = document.getElementById('mainOffCanvas').getElementsByTagName('span');
  for (let i = 0; i < bars.length; i++) {
    if (type === 'close') {
      bars[i].classList.add('--close');
    } else {
      bars[i].classList.remove('--close');
    }
  }
}
