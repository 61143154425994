
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'quotation-popup',
})
export default class extends Vue {
  private visibility: boolean = false;

  created() {
    const showPopupTimeOut = setTimeout(this.showPopup, 180000);
  }

  private showPopup(): void {
    this.visibility = true;
  }

  private ApplyQuotationPaymentMethod(): void {
    this.$store.commit('CheckoutPayment/CLEAR_PAYMENT_DETAILS');
    this.$store.dispatch('CheckoutPayment/setSelectedPaymentMethod', { code: 'quotation', title: 'Quotation', mollie_available_issuers: null, mollie_meta: { image: null } });
    this.$store.dispatch('QuotationPopup/setVisible', true);
    location.href = "/contact";
  }

  private closePopup(): void {
    this.visibility = false;
  }
}
