const initialState = () => {
  return {
    cartTotals: {},
    cartItems: {},
    totalsAvailable: false,
  };
};

const getters = {
  cartItems: (state) => state.cartItems,
  cartTotals: (state) => state.cartTotals,
  totalsAvailable: (state) => state.totalsAvailable,
};

const mutations = {
  SET_CART_TOTALS(state, payload) {
    state.cartTotals = payload;
  },
  SET_CART_ITEMS(state, payload) {
    state.cartItems = payload;
  },
  SET_TOTALS_AVAILABLE(state, payload) {
    state.totalsAvailable = payload;
  },
};

const actions = {
  async fetchCartTotals({ commit }) {
    try {
      commit('SET_TOTALS_AVAILABLE', false);
      const { data } = await this.$solarClient.get(`/api/checkout/totals/collect`);
      commit('SET_CART_TOTALS', data);
      commit('SET_CART_ITEMS', data['items']);
    } catch (err) {
      //
    } finally {
      commit('SET_TOTALS_AVAILABLE', true);
      window.dispatchEvent(new CustomEvent('CheckoutAddedProductToCartCount'));
    }
  },
};

const state = initialState();

const CheckoutTotals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutTotals;
