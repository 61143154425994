
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ServiceContainer from '@flashpointbv/solar-service-container';

@Component({
  name: 'checkout-address-shipping',
  components: {
    CheckoutAddressForm: require('./input/AddressForm.vue').default,
    CheckoutAddressSelect: require('./input/AddressSelect.vue').default,
  },
})
export default class extends Vue {
  @Prop({ required: false }) private addresses: Array<object>;

  private manualForm: boolean = false;
  private selectedAddress: object = {};

  private get shippingAddress(): object {
    return this.$store.getters['CheckoutAddress/shippingAddress'];
  }

  private get showForm(): boolean {
    return this.shippingAddress.hasOwnProperty('newAddress') || this.manualForm || !this.addresses.length;
  }

  created(): void {}

  @Watch('selectedAddress')
  handler(nv, ov): void {
    if (nv === ov) {
      return;
    }

    this.$store.dispatch('CheckoutAddress/setShippingAddress', {
      address: nv,
    });
  }
}
