
import {Component, Vue, Prop} from 'vue-property-decorator';
import ServiceContainer from "@flashpointbv/solar-service-container";
import Environment from "@flashpointbv/solar-ui-environment";
import {transformMeasureUnit} from "../../../shared/helpers/measure-unit";

@Component({
  name: 'search-result-suggested-products',
  components: {...ServiceContainer().getComponents()}
})

export default class extends Vue {
  @Prop() private resultData: Array<object>;
  @Prop() private searchQuery: string;

  private productImage(product: object): string {
    return `${Environment().get('solar_cdn_base_url')}/media/catalog/product${product['small_image']}`;
  }

  private getUnitPrice(product: object): string {
    return product['scoped_price_info'][`unit_price_${this.priceView}`].priceFormat() + this.unitSuffix(product);
  }

  private unitSuffix(product: object): string {
    return product['unit_of_measurement'] ? transformMeasureUnit(product['unit_of_measurement']) : '';
  }

  private getUrl(product: object): string {
    if (this.searchQuery.length > 5 && /^\d+$/.test(this.searchQuery)) {
      return product['url'];
    }

    return `${product['url']}?q=${this.searchQuery}`;
  }

  private get priceView(): string {
    return this.$store.getters['GlobalSettings/getPriceView'];
  }
}
