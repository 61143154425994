
import { Component, Vue } from 'vue-property-decorator';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);
extend('positive', value => {
  return value === true;
});

@Component({
  name: 'checkout-payment-agreement',
  components: {
    ValidationProvider,
  },
  data() {
    return {
      paymentAgreementAccepted: false,
    };
  },
})
export default class extends Vue {
  private get loadingPaymentData(): boolean {
    return this.$store.getters['CheckoutPayment/loadingPaymentData'];
  }
}
