import {appSessionStorage} from '../checkout/services';

enum priceView {
    incl_tax = 'incl_tax',
    excl_tax = 'excl_tax',
}

import Vue from 'vue';

const initialState = () => {
    return {
        priceView: appSessionStorage.getItem('price-visibility', priceView.incl_tax),
    }
};

const state = initialState();

const mutations = {
    SET_PRICE_VIEW(state: object, payload: object): void {
        Vue.set(state, 'priceView', payload);
        appSessionStorage.setItem('price-visibility', payload);
    }
};

const actions = {
    setPriceView({commit}, payload: string): void {
        commit('SET_PRICE_VIEW', payload);
    }
};

const getters = {
    getPriceView: (state: object) => state['priceView'],
};

const GlobalSettings = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default GlobalSettings;
