
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

@Component({
  name: 'checkout-payment-methods-default',
  components: {
    ValidationProvider,
  },
})
export default class extends Vue {
  @Ref('order') readonly order!: HTMLButtonElement;
  @Prop({ required: true }) private paymentMethod: object;
  @Prop() private selectedPaymentMethod: object;
  private selectedIssuer: string = '';

  private get methodSubTitle(): object {
    switch (this.paymentMethod['code']) {
      case 'paynl_payment_ideal':
        return {
          tooltip: 'Met iDEAL kunt  u met een Nederlandse bankrekening vertrouwd, veilig en gemakkelijk betalen via internetbankieren van uw eigen bank.',
          subtitle: 'Gratis, meest gekozen betaalmethode in Nederland',
        };
      case 'paynl_payment_afterpay':
        return {
          tooltip: null,
          subtitle: null,
        };
      case 'paynl_payment_mistercash':
        return {
          tooltip: 'U kunt met Bancontact vertrouwd, veilig en gemakkelijk betalen via internetbankieren van uw eigen bank, wanneer u een Belgische bankrekening heeft.',
          subtitle: null,
        };
      case 'paynl_payment_overboeking':
        return {
          tooltip:
            'Nadat je je bestelling hebt geplaatst, krijg je een scherm met betaalinstructies van onze betaalpartner Pay.nl te zien. Deze instructies worden tevens naar je opgegeven e-mailadres gestuurd. Je bestelling zal verstuurd worden zodra de betaling door ons ontvangen is. Let op: Het verwerken van de betaling duurt 1 tot 2 werkdagen.',
          subtitle: null,
        };
      case 'paynl_payment_maestro':
      case 'paynl_payment_visamastercard':
        return {
          tooltip: null,
          subtitle: 'Betaal veilig met uw creditcard.',
        };
      case 'paynl_payment_payconiq':
        return {
          tooltip: null,
          subtitle: 'Eenvoudig én veilig mobiel betalen met Payconiq',
        };
      case 'paynl_payment_capayable_gespreid':
        return {
          tooltip: null,
          subtitle: 'Vanaf € 100,- t/m € 3.000,-',
        };
      case 'giftcard':
        return {
          tooltip: this.$store.state['CheckoutPayment']['paymentMethodConfig']['giftcard']['name'] || '',
          subtitle: null,
        };
      default:
        return {
          tooltip: null,
          subtitle: this.$t('Zonder extra kosten.'),
        };
    }
  }

  private get isActiveMethod(): boolean {
    return this.selectedPaymentMethod['code'] === this.paymentMethod['code'];
  }

  private get issuers(): Array<object> {
    return [];
  }

  private async changeSelectedPaymentMethod(): Promise<void> {
    await this.$store.commit('CheckoutPayment/CLEAR_PAYMENT_DETAILS');
    await this.$store.dispatch('CheckoutPayment/setSelectedPaymentMethod', this.paymentMethod);
    await this.$store.dispatch('CheckoutPayment/setPaymentMethod', this.paymentMethod);
  }

  private changeSelectedIssuer(): void {
    this.$store.commit('CheckoutPayment/ADD_PAYMENT_DETAILS', { bank_id: this.selectedIssuer });
  }
}
