
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'checkout-address-option',
})
export default class extends Vue {
  @Prop() private address: object;

  private get addressTemplate(): string {
    return (
      (this.address['company'] ? this.address['company'] + ', ' : '') +
      this.address['firstname'] +
      (this.address['middlename'] ? ' ' + this.address['middlename'] : '') +
      ' ' +
      this.address['lastname'] +
      ', ' +
      this.address['street'].join(' ') +
      ', ' +
      this.address['postcode'] +
      ' ' +
      this.address['city'] +
      ', ' +
      this.address['country_code']
    );
  }
}
