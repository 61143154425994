
import {ValidationObserver} from 'vee-validate';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import QuotationPopup from '../quotation/popup.vue';
import {SolarDataLayer} from "../../shared/SolarDataLayer";
import TranslateResult = VueI18n.TranslateResult;

@Component({
  name: 'checkout-checkout',
  components: {
    QuotationPopup,
    ValidationObserver,
    CheckoutSummary: require('./summary/Summary.vue').default,
    CheckoutAddressDetail: require('./step/AddressDetail.vue').default,
    CheckoutPaymentDetail: require('./step/PaymentDetail.vue').default,
    CheckoutShippingDetail: require('./step/ShippingDetail.vue').default,
    CheckoutPaymentAgreement: require('./step/Agreement.vue').default,
  },
})
export default class extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };

  @Prop({required: false}) private mergeMessage: string;
  @Prop({required: true}) private quote: object;
  @Prop({default: false}) private isLoggedIn: boolean;
  @Prop({default: null}) private customerEmail: string;
  @Prop({default: []}) private addresses: Array<object>;
  @Prop({default: null}) private countries: Array<object>;
  @Prop({default: null}) private errorMessage: string | null;
  @Prop() private dataLayerItems: Array<object>;

  protected loadingCheckout: boolean = false;
  protected checkoutErrorMessage: TranslateResult = '';

  private get showLoginForm(): boolean {
    return this.$store.getters['CheckoutGlobal/showLoginForm'];
  }

  private get currentStep(): number {
    return this.$store.getters['CheckoutGlobal/currentStep'];
  }

  private get currentPaymentMethodIsQuotation(): boolean {
    let payment = this.$store.getters['CheckoutPayment/selectedPaymentMethod'];
    return 'quotation' === payment['code'];
  }

  private get selectedPaymentMethod(): string {
    return this.$store.getters['CheckoutPayment/selectedPaymentMethod'];
  }

  private get selectedShippingMethod(): string {
    return this.$store.getters['CheckoutShipping/shippingMethod']['method_title'];
  }

  private get dataLayerProducts(): Array<object> {
    const items = [...this.quote['items']];
    return items.map((item: object) => {
      const dataLayerItems = this.dataLayerItems.find((dataLayerItem: object) => {
        return dataLayerItem['product_id'] === item['extension_attributes']['parent_product_id'];
      });

      return {
        ...item,
        'item_group_id': item['extension_attributes']['pim_productfamilie'] || item['extension_attributes']['parent_product_id'],
        extension_attributes: {
          ...item['extension_attributes'],
          'product_id': item['extension_attributes']['parent_product_id'],
          'item_variant': this.formatOptionValues(item),
          'item_variant_id': item['extension_attributes']['product_id'],
          'category_names': dataLayerItems['extension_attributes']['category_names'],
        },
      };
    });
  }

  private formatOptionValues(item: object): string {
    if ( ! item['options']) {
      return '';
    }

    const options = JSON.parse(item['options']);

    return options.map((option: object) => {
      return option['value'];
    }).join('|');
  }

  private async mounted(): Promise<void> {
    await this.$store.dispatch('CheckoutShipping/setShippingCountries', this.countries);
    await this.$store.dispatch('CheckoutTotals/fetchCartTotals');

    if (this.isLoggedIn && this.customerEmail) {
      this.$store.commit('CheckoutGlobal/SET_CUSTOMER_EMAIL', this.customerEmail);
    }

    SolarDataLayer.init().addBeginCheckout(
        this.dataLayerProducts,
        this.quote['subtotal_incl_tax'],
        this.quote['coupon_code']
    );
  }

  private goToStep(step): void {
    if (step === this.currentStep || this.showLoginForm) {
      return;
    }

    if (step - this.currentStep > 1) {
      step = this.currentStep + 1;
    }

    this.$refs.form.validate().then((result) => {
      if (result || step < this.currentStep) {
        this.$store.dispatch('CheckoutGlobal/gotoStep', step);
        window.scrollTo(0, 0);
      } else {
        if (this.currentStep === 1) {
          const failedInputElements = document.querySelectorAll('input.failed');
          Array.from(failedInputElements).forEach((el, index) => {
            if (index === 0) {
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start',
              });
            }
            el.classList.add('--shake');
          });
        }
      }
    });
  }

  @Watch('selectedShippingMethod')
  private handleShippingInfo(nv, ov): void {
    if (nv !== ov) {
      SolarDataLayer.init().addAddShippingInfo(
          this.dataLayerProducts,
          this.quote['subtotal_incl_tax'],
          this.selectedShippingMethod,
          this.quote['coupon_code']
      );
    }
  }

  @Watch('selectedPaymentMethod')
  private handlePaymentInfo(nv, ov): void {
    if (nv !== ov) {
      SolarDataLayer.init().addAddPaymentInfo(
          this.dataLayerProducts,
          this.quote['subtotal_incl_tax'],
          this.selectedPaymentMethod['title'],
          this.quote['coupon_code']
      );
    }
  }

  private async validatedPlaceOrder(): Promise<void> {
    this.$refs.form.validate().then((result) => {
      const failedInputElements = document.querySelectorAll('input.failed');
      Array.from(failedInputElements).forEach((el, index) => {
        if (index === 0) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }
        el.classList.add('--shake');
      });

      if (failedInputElements.length > 0) {
        return;
      }

      return this.placeOrder();
    });
  }

  private async placeOrder() {
    try {
      SolarDataLayer.init().addAddPaymentInfo(
          this.dataLayerProducts,
          this.quote['subtotal_incl_tax'],
          this.selectedPaymentMethod,
          this.quote['coupon_code']
      );
      this.loadingCheckout = true;
      const {data} = await this.$store.dispatch('CheckoutPayment/placeOrder');

      if (data.hasOwnProperty('RequiredAction')) {
        window.location.href = data['RequiredAction']['RedirectURL'];
        return;
      }

      if (this.$store.state['CheckoutPayment']['selectedPaymentMethod']['code'].includes('paynl_')) {
        return window.location.replace((await this.$solarClient.get('/api/checkout/redirectPay/' + this.quote['extension_attributes']['quote_id'])).data);
      }

      window.location.replace('/checkout/onepage/success');
    } catch (err) {
      try {
        const response = JSON.parse(err.request.response);
        this.checkoutErrorMessage = '' !== response['exception']['message'] ? response['exception']['message'] : this.$t('Unable to place order, please try again later.');
        window.scrollTo(0, 0);
      } catch (e) {
        this.checkoutErrorMessage = this.$t('Unable to place order, please try again later.');
        window.scrollTo(0, 0);
      } finally {
        this.loadingCheckout = false;
      }
    }
  }
}
