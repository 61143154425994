
import { Component, Vue, Watch } from 'vue-property-decorator';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

@Component({
  name: 'checkout-payment-detail',
  components: {
    CheckoutPaymentMethodsDefault: require('./../payment/methods/Default.vue').default,
    ValidationProvider,
  },
})
export default class extends Vue {
  private get returnedFromBuckaroo(): boolean {
    return document.referrer.includes('buckaroo');
  }

  private get paymentMethods(): Array<object> {
    return this.$store.getters['CheckoutPayment/paymentMethods'];
  }

  private get selectedPaymentMethod(): object | string {
    return this.$store.getters['CheckoutPayment/selectedPaymentMethod'];
  }

  private get loadingPaymentData(): boolean {
    return this.$store.getters['CheckoutPayment/loadingPaymentData'];
  }

  @Watch('selectedPaymentMethod', { deep: true })
  async handleSelectedPaymentMethod(nv, ov) {
    if (nv && nv !== ov) {
      await this.$store.dispatch('CheckoutPayment/setPaymentMethod', nv);
    }
  }

  private async collectPaymentMethods(): Promise<any> {
    await this.$store.dispatch('CheckoutPayment/collectPaymentMethods');
    if (this.selectedPaymentMethod.hasOwnProperty('code')) {
      await this.$store.dispatch('CheckoutPayment/setSelectedPaymentMethod', this.selectedPaymentMethod);
    }
  }

  private getPaymentMethodComponent(methodCode): string {
    switch (methodCode) {
      default:
        return 'checkout-payment-methods-default';
    }
  }

  private async created(): Promise<void> {
    await this.collectPaymentMethods();
  }
}
