import {appLocalStorage, appSessionStorage} from '../services';

const initialState = () => {
  return {
    currentStep: 1,
    showLoginForm: false,
    customerEmail: appLocalStorage.getItem('checkout-customer-email', null),
    extensionAttributes: appLocalStorage.getItem('checkout-extension-attributes', {}),
    newsletterSubscribe: appLocalStorage.getItem('checkout-newsletter-subscription', true),
  };
};

const getters = {
  currentStep: (state) => state.currentStep,
  customerEmail: (state) => state.customerEmail,
  showLoginForm: (state) => state.showLoginForm,
  getExtensionAttributes: (state) => state.extensionAttributes,
};

const mutations = {
  TOGGLE_LOGIN_FORM(state) {
    state.showLoginForm = !state.showLoginForm;
  },
  SET_CURRENT_STEP(state, currentStep) {
    state.currentStep = currentStep;
  },
  SET_CUSTOMER_EMAIL(state, customerEmail) {
    state.customerEmail = customerEmail;
    appLocalStorage.setItem('checkout-customer-email', customerEmail);
  },
  SET_NEWSLETTER_SUBSCRIBE(state, status) {
    state.newsletterSubscribe = status;
  },
  ADD_EXTENSION_ATTRIBUTE(state, extensionAttribute) {
    state.extensionAttributes = {
      ...state.extensionAttributes,
      ...extensionAttribute,
    };
    appLocalStorage.setItem('checkout-extension-attributes', state.extensionAttributes);
  },
  REMOVE_EXTENSION_ATTRIBUTE(state, extensionAttribute) {
    if (state.extensionAttributes.hasOwnProperty(extensionAttribute)) {
      delete state.extensionAttributes[extensionAttribute];
    }
    appLocalStorage.setItem('checkout-extension-attributes', state.extensionAttributes);
  },
  CLEAR_EXTENSION_ATTRIBUTE(state) {
    state.extensionAttributes = {};
    appLocalStorage.removeItem('checkout-extension-attributes');
  },
};

const actions = {
  async checkCustomerEmail({ state, commit, rootState }, customerEmail) {
    try {
      return await this.$solarClient.post(`/api/checkout/customers/isEmailAvailable`, {
        customerEmail: customerEmail,
      });
    } catch (e) {
      return false;
    }
  },
  async addExtensionAttribute({ commit }, extensionAttribute) {
    commit('ADD_EXTENSION_ATTRIBUTE', extensionAttribute);
  },
  async gotoStep({ commit }, step) {
    commit('SET_CURRENT_STEP', step);
  },
  setNewsletterSubscribe({ commit }, status) {
    commit('SET_NEWSLETTER_SUBSCRIBE', status);
    commit('ADD_EXTENSION_ATTRIBUTE', {'newsletter_subscribe': status});
  },
  toggleLoginForm({ commit }) {
    commit('TOGGLE_LOGIN_FORM');
  },
};

const state = initialState();

const CheckoutGlobal = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutGlobal;
