import { Vue } from 'vue-property-decorator';

const initialState = () => {
  return {
    selectedQuantity: 1,
  };
};

const mutations = {
  SET_SELECTED_QUANTITY(state, payload) {
    Vue.set(state, 'selectedQuantity', payload);
  },
};

const actions = {
  setQuantity({ state, commit }, quantity) {
    commit('SET_SELECTED_QUANTITY', quantity);
  },
};

const getters = {
  selectedQuantity: (state) => state.selectedQuantity,
};

const ProductQuantity = {
  namespaced: true,
  initialState,
  mutations,
  actions,
  getters,
};

export default ProductQuantity;
