
import {Component, Vue, Prop} from 'vue-property-decorator';
import ServiceContainer from '@flashpointbv/solar-service-container';
import { SolarDataLayer } from "../../shared/SolarDataLayer";

@Component({
  name: 'success-datalayer',
  components: ServiceContainer().getComponents()
})

export default class extends Vue {
  @Prop() private order: object;

  private created(): void {
    SolarDataLayer.init().addPurchase(
        (this.order['items'] || []).map((item: object) => {
          return {
            ...item,
            'item_group_id': item['extension_attributes']['pim_productfamilie'] || item['extension_attributes']['parent_product_id'],
            extension_attributes: {
              ...item['extension_attributes'],
              'product_id': item['extension_attributes']['parent_product_id'],
              'item_variant': item['extension_attributes']['item_variant'],
              'item_variant_id': item['extension_attributes']['product_id'],
            },
          };
        }),
      this.order['increment_id'],
      'HouthandelOnline',
      this.order['grand_total'],
      this.order['tax_amount'],
      this.order['base_shipping_incl_tax'],
      this.order['coupon_code'] || ''
    );

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': 'enhanced_conversion_data',
      'enhanced_conversion_data': {
        'email': this.order['customer_email'] || '' ,
        'phone_number': this.order['shipping_address']['telephone'] || '' ,
        'address': {
          'first_name': this.order['shipping_address']['firstname'] || '',
          'last_name': this.order['shipping_address']['lastname'] || '',
          'street': this.order['shipping_address']['street'] || '',
          'city': this.order['shipping_address']['city'] || '',
          'postal_code': this.order['shipping_address']['postcode'] || '',
          'country': this.order['shipping_address']['country_id'] || ''
        }
      }
    });
  }
}
