
export default {
    "{name}'s dashboard": "Overzicht van {name}",
    "Account overview": "Account overzicht",
    "Achternaam is not valid": "Achternaam is niet geldig",
    "Add new address": "Nieuw adres toevoegen",
    "Add to cart": "In mijn winkelwagen",
    "Add.": "Toev.",
    "Addition": "Toevoeging",
    "Address": "Adres",
    "Address not found": "Adres niet gevonden",
    "Address overview": "Adres overzicht",
    "Addresses": "Adressen",
    "Already have an account with us? Log in {here}": "Heb je al een account? Log {here} in",
    "Do you already have an account or do you want to order for business? Log in {loginHere} or create a business account {createHere}.": "Heb je al een account of wil je zakelijk bestellen? Log {loginHere} in of maak {createHere} een zakelijk account aan.",
    "Already have an account? Click to log in": "Heb je al een account? Klik hier om in te loggen",
    "Apply": "Toepassen",
    "Back to cart": "Terug naar winkelwagen",
    "Back to order overview": "Terug naar order overzicht",
    "Billing address": "Factuuradres",
    "Billing address is different from the address above": "Factuuradres is anders dan bovenstaand adres",
    "Cart": "Winkelwagen",
    "Chamber of commerce": "Kvk nummer",
    "Change": "Aanpassen",
    "Change password": "Verander je wachtwoord",
    "Change personal details": "Persoonlijke instellingen aanpassen",
    "City": "Plaats",
    "Clear all": "Wis alles",
    "Click here to continue shopping.": "Verder winkelen",
    "Company": "Bedrijfsnaam",
    "Complete order": "Bestellen en betalen",
    "Confirm new password": "Bevestig nieuw wachtwoord",
    "Confirm password": "Bevestig Wachtwoord",
    "Contact details": "Contactgegevens",
    "Country": "Land",
    "Create account": "Account aanmaken",
    "Create business account": "Zakelijk account aanmaken",
    "Create company account": "Zakelijk account aanmaken",
    "Credit Cards": "Creditcard",
    "Current password": "Huidig wachtwoord",
    "Date": "Datum",
    "Default billing address": "Standaard factuuradres",
    "Default shipping address": "Standaard verzendadres",
    "Description": "Artikelomschrijving",
    "Did you mean:": "Bedoelde je misschien:",
    "Do you have a promotional code?": "Heb je een kortingscode?",
    "Do you have a gift voucher? Enter your code here": "Heb je een cadeaubon? Voer hier je code in",
    "Do you receive our newsletter?": "Ontvang jij onze nieuwsbrief?",
    "Don't want to login? Click {here}": "Wil je niet inloggen? Klik {here}",
    "E-mail adres is not valid": "E-mail is niet geldig",
    "Edit": "Wijzig",
    "Edit address": "Adres wijzigen",
    "Edit personal settings": "Wijzig persoonlijke instellingen",
    "Email address": "E-mailadres",
    "Enter a correct value": "Voer a.u.b. een geldige waarde in",
    "Extra Addresses": "Extra adres",
    "Failed to add to cart": "Product kon niet worden toegevoegd",
    "Female": "Vrouw",
    "First name": "Voornaam",
    "Fixed": "Vast bedrag",
    "Flat rate": "Vast bedrag",
    "Forgot password": "Wachtwoord vergeten",
    "Forgot password?": "Wachtwoord vergeten?",
    "Free": "Gratis",
    "When?": "Wanneer?",
    "Grand Total": "Totaal",
    "Hi {name}, here you can find the state of your order, payments and personal details.": "Hoi {name}, hier vind je de status van je bestellingen, betalingen en persoonlijke gegevens.",
    "Hit enter or try another search.": "Druk op enter of probeer een andere zoekterm.",
    "House no.": "Huisnummer",
    "House number": "Huisnummer",
    "Huisnummer is not valid": "Huisnummer is niet geldig",
    "I am a": "Ik ben een",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Als er een account is gevonden met {email}, zul je een e-mail ontvangen met een link om je wachtwoord te resetten.",
    "If an account with the given email address exists you will receive a password reset email.": "Indien er een account met opgegeven e-mail bestaat, ontvang je spoedig een e-mail om je wachtwoord opnieuw in te stellen.",
    "If you have any questions about the status of your order, it is best to contact customer service.": "Vragen over je bestelling? Neem contact op met de klantenservice.",
    "In stock": "Op voorraad",
    "In stock, delivered in {days}": "Op voorraad, geleverd in {days}",
    "In stock: delivery time (2-7 days)": "Op voorraad: levertijd 2-7 werkdagen",
    "Incl. Tax": "incl. BTW",
    "Item": "Artikel",
    "Last name": "Achternaam",
    "Login": "Inloggen",
    "Login or create an account": "Inloggen of account aanmaken",
    "Login to view your cart.": "Login om je winkelwagen te bekijken",
    "Logout": "Uitloggen",
    "Male": "Man",
    "Manage addresses": "Beheer adresboek",
    "Menu": "Menu",
    "Categories": "Categorieën",
    "My account": "Mijn account",
    "My orders": "Mijn bestellingen",
    "My reviews": "Mijn reviews",
    "Name (A-Z)": "Naam (A-Z)",
    "Name (Z-A)": "Naam (Z-A)",
    "New company account": "Nieuw zakelijk account",
    "New customer": "Nieuwe klant",
    "New customers": "Nieuwe klanten",
    "New password": "Nieuw wachtwoord",
    "Newest": "Nieuwste",
    "Newsletter": "Nieuwsbrief",
    "Newsletters": "Nieuwsbrief",
    "No addresses available": "Geen adres beschikbaar",
    "No default billing address available": "Er is geen standaard factuuradres beschikbaar",
    "No default shipping address available": "Er is geen standaard verzendadres beschikbaar",
    "No products were found for \"{query}\".": "Er zijn geen artikelen gevonden voor \"{query}\".",
    "No products were found in this category.": "Er zijn geen artikelen gevonden in deze categorie.",
    "Not in stock": "Niet op voorraad",
    "Not in stock, available in {days}": "Niet op voorraad, beschikbaar in {days}",
    "Order #": "Bestelling #",
    "Order {increment}": "Bestelling {increment}",
    "Order not found": "Bestelling niet gevonden",
    "Order received": "Order bevestiging",
    "Ordered items": "Bestelde artikelen",
    "Ordered products": "Bestelde producten",
    "Other, I am a:": "Anders, namelijk:",
    "Others also viewed": "Wij bevelen ook aan",
    "Our tier pricing": "Onze staffelkortingen",
    "Overview of quotation": "Overzicht van offerte",
    "Overview of your order": "Overzicht van je bestelling",
    "Password": "Wachtwoord",
    "Payment": "Betaling",
    "Payment & Shipping": "Betaling en verzending",
    "Payment details": "Betaalgegevens",
    "Payment method": "Betaalmethode",
    "Payment methods": "Betaalmethoden",
    "Per page": "Per pagina",
    "Personal details": "Gegevens & Voorkeuren",
    "Personal settings": "Persoonlijke instellingen",
    "Phone number": "Telefoonnummer",
    "Please enter a valid VAT number": "Vul een geldig BTW nummer in",
    "Please wait, loading payment methods": "Een moment geduld, de betaalmethoden worden geladen",
    "Please wait, loading shipping methods": "Een moment geduld, de verzendmethoden worden geladen",
    "Popularity": "Populariteit",
    "Postal code": "Postcode",
    "Postal code + house number": "Postcode + Huisnummer",
    "Postcode is not valid": "Postcode is niet geldig",
    "Previously viewed by you": "Recent bekeken",
    "Price": "Prijs",
    "Price (Ascending)": "Prijs (Oplopend)",
    "Price (Descending)": "Prijs (Aflopend)",
    "Pro's and cons": "Plus en minpunten",
    "Product has been added to your shopping cart": "Product is toegevoegd aan je winkelwagen",
    "Products from a previous shopping session have been added to your shopping cart. <a href='/checkout/cart'>View products.</a>": "Je producten zijn toegevoegd aan je winkelwagen. <a href='checkout/cart'>Toon producten</a>",
    "Products in quotation": "Deze producten zitten in je offerteaanvraag",
    "Quantity": "Aantal",
    "Quickly go to": "Ga snel naar",
    "Read more": "Lees meer",
    "Read this post": "Lees de blog",
    "Recent orders": "Recente bestellingen",
    "Remove": "Verwijder",
    "Request password": "Wachtwoord aanvragen",
    "Request quotation": "Offerte aanvragen",
    "Reset password": "Wachtwoord resetten",
    "Review not found": "Beoordeling niet gevonden",
    "SKU": "SKU",
    "Sample": "Monster",
    "Save": "Opslaan",
    "Save address": "Adres opslaan",
    "Search": "Zoeken",
    "Search within {name}": "Zoek binnen {name}",
    "Searching for ideas?": "Op zoek naar idee\u00ebn?",
    "Searching...": "Zoeken...",
    "Secure connection": "Veilige verbinding",
    "Select a country": "Selecteer een land",
    "Select an option": "Selecteer een optie",
    "Select product options": "Selecteer product opties",
    "Select your address": "Selecteer je adres",
    "Send email": "E-mail sturen",
    "Ship to": "Verzend naar",
    "Shipping": "Verzending",
    "Shipping & Handling": "Verzending",
    "Shipping & Handling (Flat Rate - Fixed)": "Verzending",
    "Shipping address": "Verzendadres",
    "Shipping method": "Verzendmethode",
    "Shipping methods": "Verzendmethoden",
    "Shop today's deals.": "Bekijk onze acties.",
    "Show all results for {query}": "Toon alle resultaten voor {query}",
    "Sign up": "Aanmelden",
    "Sign up for the newsletter": "Aanmelden voor de nieuwsbrief",
    "Something went wrong while recovering your password": "Er ging iets mis tijdens het herstellen van je wachtwoord",
    "Something went wrong, please try again": "Er is iets mis gegaan, probeer het nog een keer",
    "Sort by": "Sorteer op",
    "Special offers": "Gerelateerde producten",
    "Specifications": "Specificaties",
    "Street": "Straat",
    "Subscribe to our newsletter": "Schrijf je in voor onze nieuwsbrief",
    "Subtotal": "Subtotaal",
    "Successfully changed newsletter subscription.": "Nieuwsletter voorkeuren aangepast",
    "Successfully created an account, you can log in with your new credentials": "Je account is succesvol aangemaakt, je kunt vanaf nu inloggen met je gegevens",
    "Successfully removed address": "Adres is verwijderd",
    "Successfully saved address": "Je adres is opgeslagen",
    "Successfully updated customer": "Je wijzigingen zijn opgeslagen",
    "Successfully updated newsletter subscription": "Nieuwsbriefabonnement succesvol bijgewerkt",
    "Tax amount": "Waarvan BTW",
    "Telefoonnummer is not valid": "Telefoonnummer is niet geldig",
    "Telephone": "Telefoon",
    "Thank you for requesting a quote": "Bedankt voor je offerteaanvraag",
    "Thank you for your purchase!": "Bedankt voor je bestelling!",
    "The account login is incorrect or your account has been temporarily disabled. Wait and try again later.": "De account aanmelding is onjuist of uw account is tijdelijk uitgeschakeld. Wacht en probeer het later opnieuw.",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "De inloggegevens die je hebt ingevoerd zijn incorrect of je account is tijdelijk geblokkeerd. Probeer het later nog een keer.",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Deze persoonlijke pagina biedt je een overzicht van de meest recente activiteiten. Je kunt onder andere bestellingen bekijken en account informatie bijwerken!",
    "Tier prices": "Staffelprijzen",
    "I'm going to order": "Ik ga bestellen",
    "To payment": "Naar betaling",
    "To shipping": "Naar verzenden",
    "Total": "Totaal",
    "Unable to add product to cart, try again later": "Kan product niet aan winkelwagen toevoegen, probeer het later opnieuw",
    "Unable to place order, please try again later.": "Het plaatsen van de order is niet gelukt. Probeer het later opnieuw",
    "Unable to remove address": "Kan adres niet verwijderen",
    "Unable to save customer": "Het opslaan is niet gelukt",
    "Unable to update newsletter subscription": "Kan nieuwsbriefabonnement niet bijwerken",
    "VAT Number": "BTW Nummer",
    "Business order": "Zakelijk bestellen",
    "View all your orders": "Bekijk al je bestellingen",
    "View order": "Bekijk bestelling",
    "Voornaam is not valid": "Voornaam is niet geldig",
    "We have found the following address": "We hebben dit adres gevonden",
    "We will contact you regarding your quotation.": "We nemen contact met je op over deze offerteaanvraag.",
    "Welcome {name}": "Welkom {name}",
    "What are you looking for?": "Waar ben je naar op zoek?",
    "Wishlist": "Verlanglijstje",
    "You are now logged out and will return to our home page within 5 seconds.": "Je bent nu uitgelogd en zult binnen 5 seconden terugkeren naar de homepagina.",
    "You are now signed out": "Je bent nu uitgelogd",
    "You are subscribed to our newsletter": "Je bent ingeschreven voor onze nieuwsbrief",
    "You can track the order status in your account dashboard.": "Volg de status van je bestelling in je order overzicht.",
    "You have an account. Would you like to {action}?": "Je hebt al een account. Wil je {action}?",
    "You have no product(s) in your shopping cart.": "Je hebt geen product(en) in je winkelwagen",
    "You have not placed any orders yet": "Je hebt nog geen bestelling bij ons geplaatst",
    "You have not reviewed any products yet": "Je hebt nog geen product reviews achter gelaten",
    "Your account has been created successfully.": "Uw account is succesvol aangemaakt.",
    "Your account is being validated. Please check your spam folder if you have not received an e-mail within 15 minutes.": "Je account wordt gevalideerd. Als je binnen 15 minuten nog geen e-mail hebt gehad, controleer dan je spam folder.",
    "Your cart is empty": "Je hebt geen product(en) in je winkelwagen",
    "Your default billing address": "Jouw standaard factuuradres",
    "Your default shipping address": "Jouw standaard verzendadres",
    "Your order has been successfully placed.": "Je bestelling is met succes geplaatst.",
    "Your order number is: #{order}Id. You will receive an email confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes.": "Je ordernummer is: #{order}Id. Je ontvangt binnen nu en een kwartier een e-mail ter bevestiging van je bestelling met alle details. Controleer ook je spamfolder als je na een kwartier nog geen bevestigingsmail hebt ontvangen.",
    "Your order will be delivered on": "Je bestelling wordt bezorgd op",
    "Your password has been updated": "Je wachtwoord is bijgewerkt.",
    "Your quotation has been received successfully": "Je offerteaanvraag is ontvangen",
    "Your quote number is: #{order}Id. You will receive an email confirming your quote with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes.": "Je ordernummer is: #{order}Id. Je ontvangt binnen nu en een kwartier een e-mail ter bevestiging van je bestelling met alle details. Controleer ook je spamfolder als je na een kwartier nog geen bevestigingsmail hebt ontvangen.",
    "back": "terug",
    "here": "hier",
    "new": "Aan het verwerken",
    "pending": "In verwerking",
    "per piece": "per stuk",
    "subscribe": "Schijf je in",
    "successfully logged out": "Je bent succesvol uitgelogd.",
    "{count} results": "Geen artikelen | 1 artikel | {count} artikelen",
    "{productName} has been added to your shopping cart": "{productName} is toegevoegd aan je winkelwagen",
    "{size} products": "{size} artikelen",
    "Show more": "Toon meer",
    "Show less": "Toon minder",
    "From": "Vanaf",
    "This product is not in stock.": "Dit product is niet op voorraad.",
    "Choose your {option}": "Kies je {option}",
    "Product is out of stock.": "Product is niet op voorraad",
    "Choose a different variant": "Kies een andere variant",
    "Your e-mail": "Je e-mailadres",
    "Delivery time": "Levertijd: ",
    "Benefit from our tier pricing": "Profiteer van onze pakkenprijs!",
    "Amount": "Vanaf",
    "Price per length": "Stuksprijs",
    "Unit price": "Eenheidsprijs",
    "(excl. VAT)": "(excl. BTW)",
    "(incl. VAT)": "(incl. BTW)",
    "Products": "Producten",
    "Keywords": "Keywords",
    "Suggestions": "Suggesties",
    "More value for money with our volume discounts. More advantageous per level!": "Meer waar voor je geld met onze staffelkortingen. Per niveau voordeliger!",
    "Unable to add product to cart, try again later.": "De gevraagde hoeveelheid is momenteel niet beschikbaar. Voeg minder stuks toe of neem contact met ons op.",
    "Only {qty} left in stock": "Nog maar {qty} op voorraad",
    "{qty} in stock": "{qty} op voorraad",
    "Have you checked the order properly? Unfortunately, changes are no longer possible after payment for your order.": "Heb je de bestelling goed gecontroleerd? Na het betalen van jouw order zijn wijzigingen helaas niet meer mogelijk.",
    "I have checked my order (after payment the order can't be changed).": "Ik heb de bestelling goed gecontroleerd (na betaling kan de order niet meer worden gewijzigd).",
    "You have to check your order and accept it.": "U dient uw bestelling te controleren en te accepteren.",
    "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet je zeker dat het klopt?",
    "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
    "Click here to automatically retrieve your address": "Klik hier om je adres automatisch op te halen",
    "Confirm email address": "Bevestig email-adres",
    "The email addresses do not match": "E-mailadressen komen niet overeen",
    "The following products have been added to your cart.": "De volgende producten zijn toegevoegd aan je winkelwagen.",
    "Continue shopping": "Verder winkelen",
    "To shopping cart": "Naar winkelwagen",
    "Manually enter address": "Handmatig adres invullen"
}
