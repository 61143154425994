import { SolarDataLayer as BaseSolarDataLayer } from "@flashpointbv/solar-datalayer/dist";

export class SolarDataLayer extends BaseSolarDataLayer {
  static defaultProductInformation(product: object): object {
    const config = SolarDataLayer.config;

    const productData = {
      'item_name': SolarDataLayer.dataLayerValue(product[config['item_name']]),
      'item_id': SolarDataLayer.dataLayerValue(product[config['item_id']]),
      'item_group_id': SolarDataLayer.dataLayerValue(product[config['item_group_id']]),
      'item_brand': 'HouthandelOnline',
      'item_variant': SolarDataLayer.dataLayerValue(product[config['item_variant']]),
      'currency': SolarDataLayer.currency,
      'price': SolarDataLayer.dataLayerValue(SolarDataLayer.formatPrice(product[config['price']])),
      'discount': SolarDataLayer.getSalePrice(SolarDataLayer.formatPrice(product[config['old_price']]), SolarDataLayer.formatPrice(product[config['price']]))
    };

    let categoryData = {};
    for (let i = 0; i < 5; i++) {
      categoryData[`item_category${i > 0 ? i + 1 : ''}`] = SolarDataLayer.resolveCategory(Object.prototype.hasOwnProperty.call(product, config['item_categories']) ? product[config['item_categories']] : [], i);
    }

    return {
      ...productData,
      ...categoryData
    };
  }

  static quoteProductInformation(product: object): object {
    const config = SolarDataLayer.config;

    const productData = {
      'item_name': SolarDataLayer.dataLayerValue(product[config['item_name']]),
      'item_id': SolarDataLayer.dataLayerValue(product['item_id']),
      'item_group_id': SolarDataLayer.dataLayerValue(product[config['item_group_id']]),
      'item_brand': 'HouthandelOnline',
      'item_variant': SolarDataLayer.dataLayerValue(product['extension_attributes'][config['item_variant']]),
      'currency': SolarDataLayer.currency,
      'price': SolarDataLayer.dataLayerValue(SolarDataLayer.formatPrice(product['price_incl_tax'])),
      'discount': SolarDataLayer.getSalePrice(SolarDataLayer.formatPrice(product['extension_attributes']['from_price']), SolarDataLayer.formatPrice(product['price_incl_tax'])),
      'quantity': SolarDataLayer.dataLayerValue(product['qty'])
    };

    let categoryData = {};

    //Check if category data is present on the quote item
    const categoryItemsRaw = product['extension_attributes'][config['item_categories']];
    if (categoryItemsRaw) {
      const categoryItems = typeof categoryItemsRaw === 'object' ? categoryItemsRaw : JSON.parse(categoryItemsRaw);
      for (let i = 0; i < 5; i++) {
        categoryData[`item_category${i > 0 ? i + 1 : ''}`] = SolarDataLayer.resolveCategory(categoryItems, i);
      }
    }

    return {
      ...productData,
      ...categoryData
    };

  }
}
