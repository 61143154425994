
import { Component, Prop, Vue } from 'vue-property-decorator';
import Environment from '@flashpointbv/solar-ui-environment';

@Component({
  name: 'checkout-address-select',
  components: {
    CheckoutAddressOption: require('./AddressOption.vue').default,
  },
})
export default class extends Vue {
  @Prop() private addresses: Array<object>;
  @Prop({ required: true }) private type: string;

  private selectedAddress: object = {
    prefix: '',
    firstname: '',
    middlename: '',
    lastname: '',
    postcode: '',
    street: ['', '', ''],
    city: '',
    telephone: '',
    company: '',
    country_code: Environment().get('checkout_default_country') ?? 'NL',
  };

  private newAddress: object = {
    newAddress: true,
    prefix: '',
    firstname: '',
    middlename: '',
    lastname: '',
    postcode: '',
    street: ['', '', ''],
    city: '',
    telephone: '',
    company: '',
    country_code: Environment().get('checkout_default_country') ?? 'NL',
  };

  private get savedAddress(): object {
    if (this.type === 'shipping') {
      return this.$store.getters['CheckoutAddress/shippingAddress'];
    } else {
      return this.$store.getters['CheckoutAddress/billingAddress'];
    }
  }

  private get defaultAddress(): object {
    const defaultAddress = this.addresses.filter((address) => {
      return address['default_' + this.type] || false;
    });

    return defaultAddress.length ? defaultAddress[0] : {};
  }

  private created(): void {
    if (!this.savedAddress.hasOwnProperty('newAddress')) {
      if (this.addresses.length) {
        if (this.defaultAddress.hasOwnProperty('id')) {
          this.selectedAddress = this.defaultAddress;
        }

        this.selectedAddress = this.addresses[0];
      } else {
        Object.freeze(this.newAddress);
        this.selectedAddress = this.newAddress;
      }
    }
    Object.freeze(this.savedAddress);
    this.selectedAddress = this.savedAddress;

    this.$emit('change', this.selectedAddress);
  }
}
