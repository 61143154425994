import { Validator, nlLang as nl_NL } from '@upjs/facile-validator';

document.addEventListener('DOMContentLoaded', () => {
  const formElements = document.querySelectorAll('form[data-validation=true]');
  formElements.forEach((form) => {
    const v = new Validator(<HTMLFormElement>form, {
      lang: nl_NL,
      onFieldChangeValidation: true,
      on: {
        'validation:success': () => {
          (form as HTMLFormElement).submit();
        },
        'field:error': (container, element, errors) => {
          errors.forEach((error) => {
            if (element.hasAttribute('data-message') && error.cause === 'regex') {
              error.message = element.getAttribute('data-message');
            }
          });
        },
      },
      xRules: {
        vat: /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/,
      },
    });

    form.addEventListener('submit', (e) => {
      e.preventDefault();
      v.validate();
    });
  });

  let timeout;
  const passwordCheckElements = document.querySelectorAll('input[data-password-check=true]');
  passwordCheckElements.forEach((element) => {
    const strengthBadge = document.createElement('div');
    strengthBadge.classList.add('strength__badge');
    element.after(strengthBadge);

    element.addEventListener('input', () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => strengthChecker((element as HTMLInputElement).value, strengthBadge), 200);
    });
  });

  /**
   *
   * Check password strength based on the default Magento configuration
   *
   * @param passwordParameter
   * @param badgeElement
   */
  function strengthChecker(passwordParameter: string, badgeElement: HTMLElement): void {
    if (passwordParameter.length > 0) {
      badgeElement.style.display = 'block';

      const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
      const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

      if (strongPassword.test(passwordParameter)) {
        badgeElement.classList.add('--strong');
        badgeElement.textContent = 'Sterk';
      } else if (mediumPassword.test(passwordParameter)) {
        badgeElement.classList.remove('--strong');
        badgeElement.classList.add('--medium');
        badgeElement.textContent = 'Matig';
      } else {
        badgeElement.classList.remove('--medium');
        badgeElement.classList.remove('--strong');
        badgeElement.textContent = 'Zwak';
      }
      return;
    }
    badgeElement.style.display = 'none';
  }
});
