
import {Component, Prop, Vue} from 'vue-property-decorator';
import {extend, ValidationProvider} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import {ToggleButton} from 'vue-js-toggle-button';

extend('required', required);

@Component({
  name: 'checkout-vat-form',
  components: {
    ValidationProvider,
    ToggleButton,
  },
})

export default class extends Vue {
  @Prop() readonly type: string;

  private getCompanyInfo: boolean = false;

  private toggleCompanyInfo(): void {
    this.getCompanyInfo = !this.getCompanyInfo;
  }
}
