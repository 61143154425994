
import { extend, ValidationProvider } from 'vee-validate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { integer, max, min, regex, required } from 'vee-validate/dist/rules';

extend('required', required);
extend('integer', integer);
extend('min', min);
extend('max', max);
extend('regex', regex);

@Component({
  name: 'customer-address-autocomplete',
  components: {
    ValidationProvider,
  },
})
export default class extends Vue {
  @Prop() protected street: Array<String>;
  @Prop() protected city: string;
  @Prop() protected postcode: string;
  @Prop() protected registerCity: string;
  @Prop() protected registerPostcode: string;
  @Prop() protected registerStreet: string;
  @Prop() protected registerHouseNr: string;
  @Prop() protected registerAddition: string;

  @Prop() protected registerCityValue: string;
  @Prop() protected registerPostcodeValue: string;
  @Prop() protected registeredAdditionValue: string;
  @Prop() protected registeredHouseNrValue: string;

  protected autoComplete: object = {
    postcode: null,
    houseNr: null,
    houseNrAdd: null,
  };

  protected inputAddress: object = {
    street: [null, null, null],
    city: null,
    postcode: null,
  };

  private isLoading: boolean = false;
  private foundLocation: boolean = false;
  private resolvedAddress: object = {};
  private resolveManually: boolean = false;
  protected streetNr: string = '';
  protected houseNr: string = '';
  protected addition: string = '';
  protected zipcode: string = '';

  private async postcodeLookup(): Promise<any> {
    if (null !== this.autoComplete['postcode'] && null !== this.autoComplete['houseNr']) {
      this.isLoading = true;
      this.foundLocation = false;
      try {
        this.resolvedAddress = await this.$store.dispatch('CheckoutAddress/postcodeLookup', this.autoComplete);
        this.foundLocation = true;

        this.inputAddress['street'] = [this.resolvedAddress['street'], this.resolvedAddress['house_nr'], this.resolvedAddress['houseNumberAddition']];
        this.inputAddress['city'] = this.resolvedAddress['place'];
        this.inputAddress['postcode'] = this.resolvedAddress['zipcode'];
      } catch (e) {
        this.foundLocation = false;
        this.inputAddress['street'] = ['', this.inputAddress['street'][1], this.inputAddress['street'][2]];
        this.inputAddress['city'] = '';
      } finally {
        this.isLoading = false;
      }
    }
  }

  private resolvedAddressManually(): void {
    this.resolveManually = true;
  }

  private resolveAddress(): void {
    this.postcodeLookup();
    this.resolveManually = false;
  }

  private created(): void {
    this.inputAddress = {
      city: this.city,
      street: this.street,
      postcode: this.postcode,
    };

    this.autoComplete['postcode'] = this.postcode;
    this.autoComplete['houseNr'] = this.street[1] || null;
    this.autoComplete['houseNrAdd'] = this.street[2] || null;

    if (null !== this.autoComplete['postcode'] && null !== this.autoComplete['houseNr']) {
      this.postcodeLookup();
    }

    if (this.registerCityValue && this.registerPostcodeValue) {
      this.autoComplete = {
        postcode: this.registerPostcodeValue,
        houseNr: this.registeredHouseNrValue,
        houseNrAdd: this.registeredAdditionValue,
      };
      this.postcodeLookup();
    }
  }
}
