import Vue from 'vue';
import Vuex from 'vuex';
import MobileDetect from './shared/helpers/mobile-detect';
import { HttpClientFactory } from '@flashpointbv/solar-ui-http-client';
import Environment from '@flashpointbv/solar-ui-environment';
import { AxiosInstance } from 'axios';
import { appSessionStorage } from './shared/services';
import { EnvironmentContainer } from '@flashpointbv/solar-ui-environment/dist/EnvironmentContainer';

declare module 'vue/types/vue' {
  interface Vue {
    $mobileDetect: MobileDetect;
    $mageClient: AxiosInstance;
    $solarClient: AxiosInstance;
    $eventBus: Vue;
    $config: EnvironmentContainer;
    $solarCdnBaseUrl: string;
    $baseUrl: string;
  }
}

declare module 'vuex/types' {
  interface Store<S> {
    $mageClient: AxiosInstance;
    $solarClient: AxiosInstance;
    $config: EnvironmentContainer;
    $solarCdnBaseUrl: string;
    $baseUrl: string;
  }
}

const convertPrice = (value: number, locale?: string): string => {
  switch (locale) {
    case 'de_DE':
    case 'es_ES':
      return value.toFixed(2).replace('.', ',');
    case 'en_GB':
      return value.toFixed(2).replace(',00', ',-');
    default:
      return value.toFixed(2).replace('.', ',').replace(',00', ',-');
  }
};

/**
 * Price format prototype
 */
Number.prototype['priceFormat'] = function (locale = null): string {
  return convertPrice(this, locale);
};

/**
 * Price format prototype
 */
String.prototype['priceFormat'] = function (locale = null): string {
  return convertPrice(parseFloat(this), locale);
};

/**
 * Stripslashes prototype
 */
String.prototype['stripslashes'] = function (): string {
  return this.replace(/\\(.)/gm, '$1');
};

/**
 * Check for mobile devices prototype
 */
Vue.prototype.$mobileDetect = new MobileDetect();

/**
 * Inject event bus
 */
Vue.prototype.$eventBus = new Vue();

/**
 * Set clients on both Vue & Vuex
 */
let commonMageHeaders = {};

if (appSessionStorage.getItem('auth_token')) {
  commonMageHeaders['Authorization'] = `Bearer ${appSessionStorage.getItem('auth_token')}`;
}

const mageClient = HttpClientFactory.makeInstance({
  baseURL: Environment().get('mage_api_base_url'),
  headers: {
    common: commonMageHeaders,
  },
  withCredentials: true,
  timeout: 1000 * 60,
});

const solarClient = HttpClientFactory.makeInstance({
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')['content'] || '',
      'X-STORE-ID': Environment().get('active_store_id'),
    },
  },
  withCredentials: true,
});

Vue.prototype.$mageClient = mageClient;
Vue.prototype.$solarClient = solarClient;
window.SolarClient = solarClient;
Vue.prototype.$config = Environment();
Vue.prototype.$solarCdnBaseUrl = window.Config['solar_cdn_base_url'] || '';
Vue.prototype.$baseUrl = window.Config['base_url'] || '';

Vuex.Store.prototype['$mageClient'] = mageClient;
Vuex.Store.prototype['$solarClient'] = solarClient;
Vuex.Store.prototype['$config'] = Environment();
Vuex.Store.prototype['$solarCdnBaseUrl'] = window.Config['solar_cdn_base_url'] || '';
Vuex.Store.prototype['$baseUrl'] = window.Config['base_url'] || '';
