require('./shared/helpers/form-validation');

('nav.main__navigation>ul').dropDown(el => {
  el.classList.add('--active');
}, el => {
  el.classList.remove('--active');
}, 500);

import SimpleLightbox from 'simplelightbox';
window.addEventListener('load', () => {
  let gallery = new SimpleLightbox('.lightbox__container a', {
    maxZoom: 5,
    loop: false,
    overlayOpacity: 0.6,
    navText: ['', ''],
    closeText: '',
    showCounter: false,
    animationSlide: false,
    animationSpeed: 50,
    fadeSpeed: 100,
  });

  gallery.on('changed.simplelightbox', function (e) {
    const mediaGallery = document.getElementById('mediaGallery');
    const blazeSlider = mediaGallery['blazeSlider'];

    const thumbnailGallery = document.getElementById('thumbnailGallery');
    const blazeSliderThumbnail = thumbnailGallery['blazeSlider'];

    const index = e.target.getAttribute('data-index');

    blazeSlider.slideTo(index);
    blazeSliderThumbnail.slideTo(index);
  });

  const clickableMenuItems = document.getElementsByClassName('is-drilldown-submenu-parent');
  if (clickableMenuItems.length) {
    const offCanvasMenu = document.getElementById('offCanvas');
    for (let i = 0; i < clickableMenuItems.length; i++) {
      clickableMenuItems[i].addEventListener('click', function () {
        offCanvasMenu.getElementsByClassName('menu')[0].scrollTop = 0;
      });
    }
  }

  document.querySelectorAll('.collapsible-button').forEach(button => {
    button.addEventListener('click', function()  {
      this.classList.toggle('--active');
      const content = this.nextElementSibling;

      if (content.style.display === 'block') {
        content.style.display = 'none';
      } else {
        content.style.display = 'block';
      }
    });
  });

});


