import { appSessionStorage } from '../services';

const initialState = () => {
  return {
    shippingMethod: appSessionStorage.getItem('checkout-shipping-selected-method', {}),
    deliveryOption: appSessionStorage.getItem('checkout-shipping-delivery-option', ''),
    shippingMethods: [],
    shippingCountries: {},
    loadingShippingMethods: false,
  };
};

const getters = {
  shippingMethod: (state) => state.shippingMethod,
  deliveryOption: (state) => state.deliveryOption,
  shippingMethods: (state) => state.shippingMethods,
  shippingCountries: (state) => state.shippingCountries,
  loadingShippingMethods: (state) => state.loadingShippingMethods,
};

const mutations = {
  SET_SHIPPING_METHOD: (state, payload) => (state.shippingMethod = payload),
  SET_DELIVERY_OPTION: (state, payload) => (state.deliveryOption = payload),
  SET_SHIPPING_METHODS: (state, payload) => (state.shippingMethods = payload),
  SET_SHIPPING_COUNTRIES: (state, payload) => (state.shippingCountries = payload),
  SET_LOADING_SHIPPING_METHODS: (state, payload) => (state.loadingShippingMethods = payload),
};

const actions = {
  async setShippingMethod({ commit, dispatch }, shippingMethod) {
    commit('SET_SHIPPING_METHOD', shippingMethod);
    appSessionStorage.setItem('checkout-shipping-selected-method', shippingMethod);
  },
  async setDeliveryOption({ commit, dispatch }, deliveryOption) {
    commit('SET_DELIVERY_OPTION', deliveryOption);
    appSessionStorage.setItem('checkout-shipping-delivery-option', deliveryOption);
    dispatch('CheckoutGlobal/addExtensionAttribute', { deliveryOption: deliveryOption}, { root: true });
  },
  async collectShippingMethods({ rootGetters, commit, dispatch }) {
    try {
      const [_billingAddress, _shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];

      commit('SET_LOADING_SHIPPING_METHODS', true);
      const { data } = await this.$solarClient.post('/api/checkout/shipping/collectShippingMethods', {
        address: _shippingAddress,
      });

      commit('SET_SHIPPING_METHODS', data);

      if (data.length === 1) {
        dispatch('setShippingMethod', data[0]);
      }
    } catch (err) {
      //
    } finally {
      commit('SET_LOADING_SHIPPING_METHODS', false);
    }
  },
  setShippingCountries({ commit }, payload) {
    try {
      commit(
        'SET_SHIPPING_COUNTRIES',
        payload.sort((a, b) => (a['full_name_locale'] > b['full_name_locale'] && 1) || -1)
      );
    } catch (err) {
      //
    }
  },
};

const state = initialState();

const CheckoutShipping = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutShipping;
