import Vue from 'vue';

const initialState = () => {
    return {
        item_id: null,
        item_group_id: null,
    };
};

const mutations = {
    SET_DATA_LAYER_PARAM(state: object, payload: object): void {
        for (let key in payload) {
            Vue.set(state, key, payload[key]);
        }
    },
};

const actions = {
    setDataLayerParams({commit}, payload: object): void {
        commit('SET_DATA_LAYER_PARAM', payload);
    },
};

const getters = {
    dataLayerParams: (state: object) => state,
};

const ProductDataLayer = {
    namespaced: true,
    initialState,
    mutations,
    actions,
    getters,
};

export default ProductDataLayer;
