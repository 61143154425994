
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'checkout-summary-personal-address',
})
export default class extends Vue {
  @Prop({ required: true }) customerEmail: String;
  @Prop({ required: true }) customerAddressInformation: object;

  private get customAttributes(): object {
    return this.customerAddressInformation['custom_attributes'] || {};
  }
}
