import Vue from 'vue';

const initialState = () => {
    return {
        addToCartIsLoading: false,
        addToCartError: null,
    }
};

const state = initialState();

const actions = {
    async addProductToQuote({commit, dispatch}, productPayload: object): Promise<void> {
        commit('SET_ADD_TO_CART_LOADING', true);

        try {
            const {data} = await this.$solarClient.post(
                '/api/checkout/quote/addProductToQuote', {
                    cartItem: productPayload,
                }, {timeout: 25000}
            );

            if (data) {
                dispatch('ProductView/addSkuToAddedItems', data['sku'], {root: true});
                window.dispatchEvent(new CustomEvent('CheckoutAddedProductToCartEvent'));
            }
        } catch (error) {
            console.error(error);
            commit('SET_ADD_TO_CART_ERROR', error);
        } finally {
            commit('SET_ADD_TO_CART_LOADING', false);
        }
    }
};

const mutations = {
    SET_ADD_TO_CART_LOADING(state: object, payload: boolean): void {
        Vue.set(state, 'addToCartIsLoading', payload);
    },
    SET_ADD_TO_CART_ERROR(state: object, payload: object): void {
        Vue.set(state, 'addToCartError', payload);
    }
};

const getters = {
    getAddToCartIsLoading: (state: object) => state['addToCartIsLoading'],
    getAddToCartError: (state: object) => state['addToCartError'],
};

const CheckoutQuote = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default CheckoutQuote;
