const initialState = () => {
  return {
    visibility: false,
    dismissed: false,
  };
};

const state = initialState();

const mutations = {
  SET_POPUP_VISIBILITY(state, payload) {
    if (state.dismissed) {
      state.visibility = false;
    }
    state.visibility = payload;
  },
  SET_MANUALLY_DISMISSED(state, payload) {
    state.dismissed = true;
    state.visibility = false;
  },
};

const actions = {
  setVisible({ state, commit }, payload) {
    commit('SET_POPUP_VISIBILITY', payload);
  },
  setDismissed({ state, commit }, payload) {
    commit('SET_POPUP_VISIBILITY', false);
    commit('SET_MANUALLY_DISMISSED', true);
    localStorage.removeItem('checkout-last-entry');
  },
};

const getters = {
  getVisibility: (state) => state.visibility,
  getIsDismissed: (state) => state.dismissed,
};

const QuotationPopup = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default QuotationPopup;
