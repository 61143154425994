
import { Component, Vue, Prop } from 'vue-property-decorator';
import vueDebounce from 'vue-debounce';
import SearchResultSuggestedCategories from './results/SearchResultSuggestedCategories.vue';
import SearchResultSuggestedFilters from './results/SearchResultSuggestedFilters.vue';
import SearchResultSuggestedProducts from './results/SearchResultSuggestedProducts.vue';
import SearchResultSuggestedKeywords from './results/SearchResultSuggestedKeywords.vue';

Vue.use(vueDebounce);

@Component({
  name: 'search-form',
  components: {
    SearchResultSuggestedCategories,
    SearchResultSuggestedProducts,
    SearchResultSuggestedKeywords,
    SearchResultSuggestedFilters,
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el['clickOutsideEvent']);
      },
      unbind(el) {
        document.body.removeEventListener('click', el['clickOutsideEvent']);
      },
    },
  },
})
export default class extends Vue {
  @Prop() private initialQuery: string;

  private query: string = '';
  private autocompleteLoading: boolean = false;
  private isSearchVisible: boolean = false;
  private searchResults: object = {
    suggested_keywords: [],
    suggested_categories: [],
    suggested_filters: [],
    suggested_products: [],
  };

  private get isSearchEmpty(): boolean {
    return (this.searchResults['suggested_categories'] || []).length === 0
      && (this.searchResults['suggested_filters'] || []).length === 0
      && (this.searchResults['suggested_products'] || []).length === 0
      && (this.searchResults['suggested_keywords'] || []).length === 0;

  }

  private handleKeyDown(): void {
    if (this.isSearchEmpty) {
      this.autocompleteLoading = true;
    }
  }

  private async executeAutocomplete(): Promise<void> {
    if (this.query.length < 3) {
      this.autocompleteLoading = false;
      this.isSearchVisible = false;
      if (this.$mobileDetect.mobile()) {
        document.body.classList.remove('no-scroll');
      }
      return;
    }

    this.searchResults['suggested_categories'] = [];
    this.searchResults['suggested_keywords'] = [];
    this.searchResults['suggested_filters'] = [];
    this.searchResults['suggested_products'] = [];

    const {data} = await this.$solarClient.get(`/api/catalog/search/autocomplete?query=${this.query}`);
    this.searchResults['suggested_categories'] = data.category_results?.matches || [];
    this.searchResults['suggested_keywords'] = data.keyword_results?.matches || [];
    this.searchResults['suggested_filters'] = data.filter_results?.matches || [];
    this.searchResults['suggested_products'] = data.product_results?.matches || [];

    this.autocompleteLoading = false;
    this.isSearchVisible = true;
    if (this.$mobileDetect.mobile()) {
      document.body.classList.add('no-scroll');
    }
  }

  private closeSearchHolder(): void {
    if (this.isSearchVisible) {
      this.isSearchVisible = false;
      this.autocompleteLoading = false;
      this.query = '';
      this.searchResults = {
        suggested_categories: [],
        suggested_keywords: [],
        suggested_filters: [],
        suggested_products: [],
      };
      if (this.$mobileDetect.mobile()) {
        document.body.classList.remove('no-scroll');
      }
    }
  }

  mounted(): void {
    if (this.$mobileDetect.mobile()) {
      window.addEventListener('click', (e) => {
        if ((e.target as HTMLElement).classList.contains('search__autocomplete__dropdown')) {
          this.closeSearchHolder();
        }
      });
    }
  }

  private created(): void {
    this.query = this.initialQuery;
  }
}
