
import { Component, Prop, Vue } from 'vue-property-decorator';
import Tablerate from './components/Tablerate.vue';

@Component({
  name: 'checkout-shipping-methods-default',
})
export default class extends Vue {
  @Prop({ required: true }) private shippingMethod: object;
  @Prop() private selectedShippingMethod: object;

  private get isActiveMethod(): boolean {
    return (this.selectedShippingMethod || {})['method_code'] === this.shippingMethod['method_code'];
  }

  private get getMethodTitle(): string {
    return this.shippingMethod['method_title'].replace(/#.*$/, '').trim();
  }

  private get getSubTitle(): string {
    if(this.shippingMethod['method_code'] !== 'amstrates') {
      return this.shippingMethod['method_title'];
    } else {
      if(this.shippingMethod.hasOwnProperty('extension_attributes')) {
        return this.shippingMethod['extension_attributes']['amstartes_comment'];
      }
    }
  }

  private get deliveryOptions(): Object {
    if(this.shippingMethod['extension_attributes']['delivery_options']) {
      return JSON.parse(this.shippingMethod['extension_attributes']['delivery_options']);
    }

    return {};
  }

  private async changeSelectedShippingMethod(method): Promise<void> {
    await this.$store.dispatch('CheckoutShipping/setShippingMethod', method);

    if (!this.selectedDeliveryOption && this.deliveryOptions) {

      this.$store.dispatch('CheckoutShipping/setDeliveryOption', Object.values(this.deliveryOptions)[0]['code']);
    }
  }

  private get selectedDeliveryOption(): string {
    return this.$store.getters['CheckoutShipping/deliveryOption'];
  }
}
