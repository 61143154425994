
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    "customer-address-autocomplete": require("./AddressAutocomplete.vue")
      .default,
  },
})
export default class CustomerCountryAndAddress extends Vue {
  @Prop({ required: true }) protected countries!: Record<string, string>;
  @Prop() protected country!: string;
  @Prop() protected city!: string;
  @Prop() protected housenumber!: string;
  @Prop() protected street!: Array<string>;
  @Prop() protected addition!: string;
  @Prop() protected postcode!: string;

  protected selectedCountry: string = "";
  protected enteredCity: string = "";
  protected enteredStreet: string[] = [];
  protected enteredHouseNr: string = "";
  protected enteredAddition: string = "";
  protected enteredPostcode: string = "";
  protected manualInput: boolean = false;

  @Watch("selectedCountry")
  onSelectedCountryChange(newCountry: string) {
    if (newCountry === "BE") this.manualInput = true;
    if (newCountry === "NL") this.manualInput = false;

    if (newCountry != "NL") {
      document.getElementById("coc_number__container").style.display = "none";
      document.getElementById("coc_number").removeAttribute('data-rules');
    } else {
      document.getElementById("coc_number__container").style.display = "block";
      document.getElementById("coc_number").setAttribute('data-rules', 'required');

    }
  }

  private created(): void {
    const fields = {
      country: "selectedCountry",
      city: "enteredCity",
      street: "enteredStreet",
      postcode: "enteredPostcode",
      housenumber: "enteredHouseNr",
      addition: "enteredAddition",
    };

    for (const [field, target] of Object.entries(fields)) {
      if (this[field]) {
        this[target] = this[field];
      }
    }
  }
}
